import React, { useEffect, useState } from "react";
import AlertModal from "../components/AlertModal";
import "../styles/AdmsCreateNewUsers.css";
import "../styles/AdmsCreateCourses.css";
import { MaterialsData, TurmaOptionData } from "../interfaces/Turmas";
import { v4 as uuidv4 } from "uuid"; // Importa a função v4 da biblioteca uuid
import {
  addMaterialsFirestore,
  getMaterialsFirestore,
  removeMaterialsFirestore,
  updateMaterialsFirestore,
} from "../database/firestore";
import { Spinner } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";

const AdmsCreateMaterial = (props: { state: any }) => {
  const { state } = props;
  const cursodata = state?.cursos;

  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [materials, setMaterials] = useState<MaterialsData[]>([]);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [turmaId, setTurmaId] = useState("");
  const [iframeLink, setIframeLink] = useState("");
  const [downloadLink, setDownloadLink] = useState(
    "https://drive.google.com/uc?export=download&id="
  );
  const [active, setActive] = useState(true);
  const [type, setType] = useState("");
  const [folderName, setFolderName] = useState("");
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [editingMaterial, setEditingMaterial] = useState<MaterialsData | any>(
    null
  );
  const [startEditingMaterial, setStartEditingMaterial] = useState(false);
  const [selectedTurma, setSelectedTurma] = useState(
    materials.length ? materials[0].turmaId : "LT1NR"
  );

  useEffect(() => {
    loadMaterials();
  }, []);

  const loadMaterials = async () => {
    try {
      const materialsData = await getMaterialsFirestore();
      setMaterials(materialsData);
    } catch (error) {
      setError(`Erro ao buscar Materiais: ${error}`);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setError("");
    setSuccessMessage("");
    // Optionally, clear the email and password fields after successful signup
  };

  const handleCreateMaterial = async () => {
    setSpinnerLoading(true);

    const materialData: MaterialsData = {
      id: uuidv4(),
      created: Date.now().toString(),
      fileName,
      turmaId,
      iframeLink,
      downloadLink,
      type,
      active,
      folderName,
      youtubeUrl,
    };

    try {
      await addMaterialsFirestore(materialData);
      // SignUp successful, update the state with success message
      setSuccessMessage("Cadastro do Material foi realizado com sucesso!");
      setShowModal(true);
      setSpinnerLoading(false);

      //limpar os inputs
      setFileName("");
      setTurmaId("");
      setIframeLink("");
      setDownloadLink("https://drive.google.com/uc?export=download&id=");
      setActive(true);
      setType("");
      setFolderName("");
      setYoutubeUrl("");

      //chama os Materiais
      loadMaterials();
      // window.location.reload();
    } catch (error: any) {
      setError(error.message);
      setShowModal(true);
      setSpinnerLoading(false);
    }
  };

  const handleUpdateMaterial = async () => {
    setSpinnerLoading(true);

    const materialData: MaterialsData = {
      id: editingMaterial.id,
      created: editingMaterial.created,
      fileName,
      turmaId,
      iframeLink,
      downloadLink,
      type,
      active,
      folderName,
      youtubeUrl,
    };

    try {
      await updateMaterialsFirestore(materialData);

      setSuccessMessage("Material foi atualizado com sucesso!");
      setShowModal(true);
      setSpinnerLoading(false);

      //limpar os inputs
      setFileName("");
      setTurmaId("");
      setIframeLink("");
      setDownloadLink("https://drive.google.com/uc?export=download&id=");
      setActive(true);
      setType("");
      setFolderName("");
      setYoutubeUrl("");
      setStartEditingMaterial(false);

      //chama os Materiais
      loadMaterials();
    } catch (error: any) {
      setError(error.message);
      setShowModal(true);
      setSpinnerLoading(false);
    }
  };

  const handleEditMaterial = (materialId: string) => {
    const materialToEdit = materials.find(
      (material) => material.id === materialId
    );

    if (materialToEdit) {
      setStartEditingMaterial(true);
      setEditingMaterial(materialToEdit);
      setFileName(materialToEdit.fileName);
      setTurmaId(materialToEdit.turmaId);
      setIframeLink(materialToEdit.iframeLink);
      setDownloadLink(materialToEdit.downloadLink);
      setType(materialToEdit.type);
      setActive(materialToEdit.active);
      setFolderName(materialToEdit.folderName);
      setYoutubeUrl(materialToEdit.youtubeUrl ? materialToEdit.youtubeUrl : "");
    }
  };

  // Função para filtrar materiais por turma
  const filteredMaterials = materials.filter(
    (item) => item.turmaId === selectedTurma && item.active === true
  );

  // Obter lista de turmas únicas
  const uniqueTurmas = Array.from(
    new Set(materials.map((item) => item.turmaId))
  );

  // Função para lidar com a mudança de turma selecionada
  const handleTurmaChange = (event: any) => {
    setSelectedTurma(event.target.value);
  };

  const handleDeleteMaterial = async (materialId: string) => {
    await removeMaterialsFirestore(materialId);
    loadMaterials();
  };

  const materialsList = () => {
    const sortedMaterials = [...filteredMaterials].sort((a, b) => {
      const turmaComparison = a.turmaId.localeCompare(b.turmaId);
      if (turmaComparison !== 0) {
        return turmaComparison;
      } else {
        const folderComparison = a.folderName.localeCompare(b.folderName);
        if (folderComparison !== 0) {
          return folderComparison;
        } else {
          return a.fileName.localeCompare(b.fileName);
        }
      }
    });

    return (
      <div className="container-materials">
        <div
          className="row"
          style={{ display: "grid", gridTemplateColumns: "1fr", gap: "20px" }}
        >
          {sortedMaterials.map((material, index) => (
            <div
              className="d-flex"
              key={index}
              style={{
                color: "#ffffff",
                gap: "20px",
                margin: "10px",
                justifyContent: "space-between",
                border: "1px solid #fff",
                padding: "10px",
              }}
            >
              <span style={{ width: "95%" }}>{`${index + 1}. ${
                material.fileName
              } - ${material.turmaId} - ${material.folderName}`}</span>

              <div
                style={{
                  display: "flex",
                  color: "#ffffff",
                  justifyContent: "space-between",
                  width: "60px",
                }}
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDeleteMaterial(material.id)}
                >
                  <FaTrash />
                </span>

                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => handleEditMaterial(material.id)}
                >
                  <FaEdit />
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="adms-page-create">
      <div className="card-container-users">
        <strong
          style={{
            display: "flex",
            textAlign: "center",
            width: "100%",
            color: "#fff",
            textTransform: "uppercase",
            justifyContent: "center",
            fontSize: "24px",
            marginBottom: "20px",
          }}
        >
          Subir materiais das turmas
        </strong>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <form className="form-signup new-courses was-validated gap-4 d-flex flex-column">
            <div className="div-form-group">
              <label className="div-input-label" htmlFor="fileName">
                Nome do Arquivo:
              </label>
              <input
                type="text"
                className="div-input-field"
                id="fileName"
                value={fileName}
                onChange={(event) => setFileName(event.target.value)}
                required
              />
            </div>

            <div className="div-form-group">
              <label className="div-input-label" htmlFor="turma">
                Qual a turma que vai receber o material?
              </label>
              <select
                className="div-input-field input-select"
                id="turma"
                onChange={(event) => {
                  if (event.target.value !== "") {
                    setTurmaId(event.target.value);
                  }
                }}
                onSelect={(event) => {
                  setTurmaId(event.currentTarget.value);
                }}
                value={turmaId}
                autoComplete="off"
                required
              >
                <option value="" disabled hidden>
                  Escolha a turma que vai receber o material!
                </option>
                {cursodata
                  .filter((opcao: TurmaOptionData) =>
                    [
                      "LT1NR",
                      "LT2NR",
                      "LT3NR",
                      "LT4NR",
                      "VT1NR",
                      "ONLT1NR",
                    ].includes(opcao.courseCode)
                  )
                  .map((opcao: TurmaOptionData) => (
                    <option key={opcao.courseCode} value={opcao.courseCode}>
                      {opcao.nomeDoCurso}
                    </option>
                  ))}
              </select>
            </div>

            <div className="div-form-group">
              <label className="div-input-label" htmlFor="iframeLink">
                Link do Iframe do arquivo:
              </label>
              <input
                type="text"
                className="div-input-field"
                id="iframeLink"
                value={iframeLink}
                onChange={(event) => setIframeLink(event.target.value)}
              />
            </div>

            <div className="div-form-group">
              <label className="div-input-label" htmlFor="downloadLink">
                Link de download do arquivo:
              </label>
              <input
                type="text"
                className="div-input-field"
                id="downloadLink"
                value={downloadLink}
                onChange={(event) => setDownloadLink(event.target.value)}
              />
            </div>

            <div className="div-form-group">
              <label className="div-input-label" htmlFor="type">
                Escolha o tipo do seu material?
              </label>
              <select
                className="div-input-field input-select"
                id="type"
                onChange={(event) => {
                  if (event.target.value !== "") {
                    setType(event.target.value);
                  }
                }}
                onSelect={(event) => {
                  setType(event.currentTarget.value);
                }}
                value={type}
                autoComplete="off"
                required
              >
                <option value="" disabled hidden>
                  Selecione o tipo de material!
                </option>
                <option value="pdf">PDF</option>
                <option value="video">Vídeo aula</option>
                <option value="folder">Pasta de Arquivos</option>
              </select>
            </div>

            <div className="div-form-group">
              <label className="div-input-label" htmlFor="active">
                Esconder ou mostrar o arquivo?
              </label>
              <select
                className="div-input-field input-select"
                id="active"
                onChange={(event) => {
                  if (event.target.value !== "") {
                    setActive(event.target.value === "true" ? true : false);
                  }
                }}
                onSelect={(event) => {
                  setActive(
                    event.currentTarget.value === "true" ? true : false
                  );
                }}
                value={active ? "true" : "false"}
                autoComplete="off"
                required
              >
                <option value="" disabled hidden>
                  Esconder ou mostrar o arquivo?
                </option>
                <option value="true">Mostrar arquivo</option>
                <option value="false">Esconder arquivo</option>
              </select>
            </div>

            <div className="div-form-group">
              <label className="div-input-label" htmlFor="folderName">
                Nome e número da aula:
              </label>
              <input
                type="text"
                className="div-input-field"
                id="folderName"
                value={folderName}
                onChange={(event) => setFolderName(event.target.value)}
                placeholder="Ex.: Aula 1"
                required
              />
            </div>

            <div className="div-form-group">
              <label className="div-input-label" htmlFor="youtubeUrl">
                Link de Youtube/Vídeo Aula:
              </label>
              <input
                type="text"
                className="div-input-field"
                id="youtubeUrl"
                value={youtubeUrl}
                onChange={(event) => setYoutubeUrl(event.target.value)}
                disabled={type !== "video"}
              />
            </div>

            {startEditingMaterial ? (
              <button
                disabled={spinnerLoading}
                className="btn btn-primary"
                onClick={handleUpdateMaterial}
                style={{
                  width: "100%",
                  maxWidth: "200px",
                  alignSelf: "center",
                }}
              >
                {spinnerLoading ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  "ATUALIZAR MATERIAL"
                )}
              </button>
            ) : (
              <button
                disabled={spinnerLoading}
                className="btn btn-primary"
                onClick={handleCreateMaterial}
                style={{
                  width: "100%",
                  maxWidth: "200px",
                  alignSelf: "center",
                }}
              >
                {spinnerLoading ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  "SALVAR ARQUIVO"
                )}
              </button>
            )}
          </form>

          <div className="card-container-schedules w-100 mt-4">
            <div
              className="w-100"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <strong
                style={{
                  display: "flex",
                  textAlign: "center",
                  width: "100%",
                  color: "#fff",
                  textTransform: "uppercase",
                  justifyContent: "center",
                  fontSize: "24px",
                  marginBottom: "20px",
                }}
              >
                {materials.length
                  ? "Materiais disponíveis"
                  : "Nenhum Material encontrado no momento"}
              </strong>

              <select
                value={selectedTurma}
                onChange={handleTurmaChange}
                style={{ marginBottom: "20px", padding: "10px" }}
              >
                {uniqueTurmas.map((turma) => (
                  <option key={turma} value={turma}>
                    {turma}
                  </option>
                ))}
              </select>

              <div className="d-flex card-total-schedules">
                <div
                  id="totalResults"
                  className="d-flex total-results-schedules"
                >
                  {materialsList()}
                </div>
              </div>
            </div>
          </div>
        </div>

        {showModal && successMessage && (
          <AlertModal message={successMessage} onClose={handleCloseModal} />
        )}

        {showModal && error && (
          <AlertModal message={error} onClose={handleCloseModal} />
        )}
      </div>
    </div>
  );
};

export default AdmsCreateMaterial;
